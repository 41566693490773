import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49d8176c = () => interopDefault(import('../src/pages/library.vue' /* webpackChunkName: "" */))
const _f3402104 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _bc611844 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _000d1a30 = () => interopDefault(import('../src/pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _141e1021 = () => interopDefault(import('../src/pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _0e301548 = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5dd02d2c = () => interopDefault(import('../src/pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _f8f4a99e = () => interopDefault(import('../src/pages/extracts.vue' /* webpackChunkName: "pages/extracts" */))
const _57e58cc7 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _6ac1de5f = () => interopDefault(import('../src/pages/forParents.vue' /* webpackChunkName: "pages/forParents" */))
const _7b3088b2 = () => interopDefault(import('../src/pages/forStudents.vue' /* webpackChunkName: "pages/forStudents" */))
const _779c2f6b = () => interopDefault(import('../src/pages/forTeachers.vue' /* webpackChunkName: "pages/forTeachers" */))
const _a13b8dde = () => interopDefault(import('../src/pages/google-analytics.vue' /* webpackChunkName: "pages/google-analytics" */))
const _23401b56 = () => interopDefault(import('../src/pages/helpCenter.vue' /* webpackChunkName: "pages/helpCenter" */))
const _6fbf6d5a = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1b0d3eab = () => interopDefault(import('../src/pages/offline-fallback.vue' /* webpackChunkName: "pages/offline-fallback" */))
const _7e5bbafa = () => interopDefault(import('../src/pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _69e3d612 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _6bd88d1c = () => interopDefault(import('../src/pages/savedForOffline.vue' /* webpackChunkName: "pages/savedForOffline" */))
const _6ebf50f2 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _59e56e82 = () => interopDefault(import('../src/pages/state.vue' /* webpackChunkName: "pages/state" */))
const _7fa8e0bf = () => interopDefault(import('../src/pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _4f9da923 = () => interopDefault(import('../src/pages/thank_you_for_registration.vue' /* webpackChunkName: "pages/thank_you_for_registration" */))
const _7f475c16 = () => interopDefault(import('../src/pages/verify_email.vue' /* webpackChunkName: "pages/verify_email" */))
const _7b24e6e5 = () => interopDefault(import('../src/pages/category/favorites.vue' /* webpackChunkName: "pages/category/favorites" */))
const _57ab0f18 = () => interopDefault(import('../src/pages/dashboard/reading-group.vue' /* webpackChunkName: "pages/dashboard/reading-group" */))
const _bb8a2260 = () => interopDefault(import('../src/pages/subscription/cards.vue' /* webpackChunkName: "pages/subscription/cards" */))
const _3ccfaa41 = () => interopDefault(import('../src/pages/subscription/history.vue' /* webpackChunkName: "pages/subscription/history" */))
const _cf1b4d3a = () => interopDefault(import('../src/pages/affiliates/_affiliateId.vue' /* webpackChunkName: "pages/affiliates/_affiliateId" */))
const _0afa7db3 = () => interopDefault(import('../src/pages/author/_authorHash.vue' /* webpackChunkName: "pages/author/_authorHash" */))
const _3b381978 = () => interopDefault(import('../src/pages/category/_name.vue' /* webpackChunkName: "pages/category/_name" */))
const _c51b7db0 = () => interopDefault(import('../src/pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _ff742370 = () => interopDefault(import('../src/pages/developlibraryset/_setId.vue' /* webpackChunkName: "pages/developlibraryset/_setId" */))
const _0919a776 = () => interopDefault(import('../src/pages/developstudycourse/_syllabusId.vue' /* webpackChunkName: "pages/developstudycourse/_syllabusId" */))
const _92ec6ee2 = () => interopDefault(import('../src/pages/genre/_name.vue' /* webpackChunkName: "pages/genre/_name" */))
const _478b1551 = () => interopDefault(import('../src/components/pages/LoginPage/LoginPage.vue' /* webpackChunkName: "" */))
const _b73dae0a = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bloggerAuth",
    redirect: "about",
    name: "bloggerAuth"
  }, {
    path: "/library_(\\w{2})",
    component: _49d8176c,
    name: "library_language"
  }, {
    path: "/about/",
    component: _f3402104,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/account/",
    component: _bc611844,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/affiliates/",
    component: _000d1a30,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates"
  }, {
    path: "/assessment/",
    component: _141e1021,
    pathToRegexpOptions: {"strict":true},
    name: "assessment"
  }, {
    path: "/dashboard/",
    component: _0e301548,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/delete-account/",
    component: _5dd02d2c,
    pathToRegexpOptions: {"strict":true},
    name: "delete-account"
  }, {
    path: "/extracts/",
    component: _f8f4a99e,
    pathToRegexpOptions: {"strict":true},
    name: "extracts"
  }, {
    path: "/faq/",
    component: _57e58cc7,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/forParents/",
    component: _6ac1de5f,
    pathToRegexpOptions: {"strict":true},
    name: "forParents"
  }, {
    path: "/forStudents/",
    component: _7b3088b2,
    pathToRegexpOptions: {"strict":true},
    name: "forStudents"
  }, {
    path: "/forTeachers/",
    component: _779c2f6b,
    pathToRegexpOptions: {"strict":true},
    name: "forTeachers"
  }, {
    path: "/google-analytics/",
    component: _a13b8dde,
    pathToRegexpOptions: {"strict":true},
    name: "google-analytics"
  }, {
    path: "/helpCenter/",
    component: _23401b56,
    pathToRegexpOptions: {"strict":true},
    name: "helpCenter"
  }, {
    path: "/library/",
    component: _49d8176c,
    pathToRegexpOptions: {"strict":true},
    name: "library"
  }, {
    path: "/login/",
    component: _6fbf6d5a,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/offline-fallback/",
    component: _1b0d3eab,
    pathToRegexpOptions: {"strict":true},
    name: "offline-fallback"
  }, {
    path: "/onboarding/",
    component: _7e5bbafa,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding"
  }, {
    path: "/pricing/",
    component: _69e3d612,
    pathToRegexpOptions: {"strict":true},
    name: "pricing"
  }, {
    path: "/savedForOffline/",
    component: _6bd88d1c,
    pathToRegexpOptions: {"strict":true},
    name: "savedForOffline"
  }, {
    path: "/search/",
    component: _6ebf50f2,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/state/",
    component: _59e56e82,
    pathToRegexpOptions: {"strict":true},
    name: "state"
  }, {
    path: "/subscription/",
    component: _7fa8e0bf,
    pathToRegexpOptions: {"strict":true},
    name: "subscription"
  }, {
    path: "/thank_you_for_registration/",
    component: _4f9da923,
    pathToRegexpOptions: {"strict":true},
    name: "thank_you_for_registration"
  }, {
    path: "/verify_email/",
    component: _7f475c16,
    pathToRegexpOptions: {"strict":true},
    name: "verify_email"
  }, {
    path: "/category/favorites/",
    component: _7b24e6e5,
    pathToRegexpOptions: {"strict":true},
    name: "category-favorites"
  }, {
    path: "/dashboard/reading-group/",
    component: _57ab0f18,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-reading-group"
  }, {
    path: "/subscription/cards/",
    component: _bb8a2260,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-cards"
  }, {
    path: "/subscription/history/",
    component: _3ccfaa41,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-history"
  }, {
    path: "/",
    component: _49d8176c,
    name: "library"
  }, {
    path: "/affiliates/:affiliateId/",
    component: _cf1b4d3a,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates-affiliateId"
  }, {
    path: "/author/:authorHash?/",
    component: _0afa7db3,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorHash"
  }, {
    path: "/category/:name?/",
    component: _3b381978,
    pathToRegexpOptions: {"strict":true},
    name: "category-name"
  }, {
    path: "/collection/:id?/",
    component: _c51b7db0,
    pathToRegexpOptions: {"strict":true},
    name: "collection-id"
  }, {
    path: "/developlibraryset/:setId?/",
    component: _ff742370,
    pathToRegexpOptions: {"strict":true},
    name: "developlibraryset-setId"
  }, {
    path: "/developstudycourse/:syllabusId?/",
    component: _0919a776,
    pathToRegexpOptions: {"strict":true},
    name: "developstudycourse-syllabusId"
  }, {
    path: "/genre/:name?/",
    component: _92ec6ee2,
    pathToRegexpOptions: {"strict":true},
    name: "genre-name"
  }, {
    path: "/access_token*",
    component: _478b1551,
    name: "access_token"
  }, {
    path: "/state*",
    component: _478b1551
  }, {
    path: "/:slug?/",
    component: _b73dae0a,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
